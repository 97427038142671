// APP JS

document.addEventListener('DOMContentLoaded', function(e) {

	// Colapsible widgets

	const colapsibleWidgets = document.querySelectorAll(".widget-colapsible");

	colapsibleWidgets.forEach(widget => {

		const widgetTitle = widget.querySelector(".widget-title");
		const widgetTitleIcon = widget.querySelector(".widget-title i");
		const widgetContent = widget.querySelector(".widget-content");

		if (window.innerWidth < 576) {
			
			widgetTitle.addEventListener("click", function() {
				if (widgetContent.style.display === "block") {
					widgetContent.style.display = "none";
				} else {
					widgetContent.style.display = "block";
				}
				widgetTitleIcon.classList.toggle("icon-chevron-down");
				widgetTitleIcon.classList.toggle("icon-chevron-up");
			});

		} else {

			widgetTitle.style.cursor = "default";
			widgetContent.style.display = "block";
			
		}

	});

	// Sliders
	// https://swiperjs.com/get-started

	const profileGallery = new Swiper('.profile-gallery', {
		slidesPerView: 1,
		spaceBetween: 10,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 15,
			},
			992: {
				slidesPerView: 3,
				spaceBetween: 15,
			},
			1200: {
				slidesPerView: 3,
				spaceBetween: 15,
			},
			1400: {
				slidesPerView: 5,
				spaceBetween: 15,
			},
		},
	});

	// Lightboxes
	// https://fancyapps.com/fancybox/getting-started/

	Fancybox.bind("[data-fancybox]", {
		Thumbs: false,
		Toolbar: {
			display: {
				left: ["infobar"],
				middle: [
					"zoomIn",
					"zoomOut",
					"toggle1to1"
				],
				right: ["close"],
			},
		},
	});

	// Form Validation
	// https://formvalidation.io/
	// https://formvalidation.io/guide/getting-started/usage/
	// https://formvalidation.io/guide/plugins/declarative/

	const dynamicForms = document.querySelectorAll('.dynamic-form');

	dynamicForms.forEach(form => {

		FormValidation.formValidation(form, {
				plugins: {
					declarative: new FormValidation.plugins.Declarative(),
					trigger: new FormValidation.plugins.Trigger(),
					autoFocus: new FormValidation.plugins.AutoFocus(),
					submitButton: new FormValidation.plugins.SubmitButton(),
					message: new FormValidation.plugins.Message({
						// This function indicates where we can display the error message
						// `field` is the field name
						// `element` is the field element
						container: function(field, element) {
							// Find the form group element
							const fieldGroup = element.closest('.form-group');
							// Find the error message
							return fieldGroup.querySelector('.err');
						},
					}),
				},
			})
			.on('core.element.validated', function(e) {
				// This event is triggered after validating a field
				// See https://formvalidation.io/guide/events/core.element.validated
				if (e.valid) {
					e.elements.forEach(function(ele) {
						ele.classList.add('is-valid');
						ele.classList.remove('is-invalid');
					});
				} else {
					e.elements.forEach(function(ele) {
						ele.classList.add('is-invalid');
						ele.classList.remove('is-valid');
					});
				}
			})
			.on('core.form.valid', e => {

				document.dispatchEvent(new CustomEvent('dynamicFormsSubmit', {
					detail: {
						FormValidation: e,
						form: form,
					},
					cancelable: true
				}));

			});

	});

	// Modals

	const modalTriggers = document.querySelectorAll(".modal-trigger");

	modalTriggers.forEach(function (trigger) {
		
		const modalId = trigger.dataset.modal;
		const modalOverlay = document.getElementById(modalId);
		const modalClose = modalOverlay.querySelector(".modal-close");

		trigger.addEventListener("click", function () {
			modalOverlay.style.display = "block";
			document.body.classList.toggle("overflow-hidden");
		});

		modalClose.addEventListener("click", function () {
			modalOverlay.style.display = "none";
			document.body.classList.toggle("overflow-hidden");
		});

		modalOverlay.addEventListener("click", function (event) {
			// Check if the clicked element is the modal overlay itself
			if (event.target === modalOverlay) {
				modalOverlay.style.display = "none";
				document.body.classList.toggle("overflow-hidden");
			}
		});

	});

	// Filter

	const filterTriggers = document.querySelectorAll(".filter-trigger");

	filterTriggers.forEach(function (trigger) {
		
		const filterId = trigger.dataset.filter;
		const filterOverlay = document.getElementById(filterId);
		const filterClose = filterOverlay.querySelector(".filter-close");

		trigger.addEventListener("click", function () {
			filterOverlay.style.display = "block";
			document.body.classList.toggle("overflow-hidden");
		});

		filterClose.addEventListener("click", function () {
			filterOverlay.style.display = "none";
			document.body.classList.toggle("overflow-hidden");
		});

		filterOverlay.addEventListener("click", function (event) {
			// Check if the clicked element is the filter overlay itself
			if (event.target === filterOverlay) {
				filterOverlay.style.display = "none";
				document.body.classList.toggle("overflow-hidden");
			}
		});

	});

	// Locations

	const locationsContainers = document.querySelectorAll(".locations");

	locationsContainers.forEach((container) => {
		const triggerButton = container.querySelector(".locations-trigger");
		const listElement = container.querySelector("ul");
		const allListItems = listElement.querySelectorAll("li");
		const hiddenListItems = Array.from(allListItems).slice(12);

		let isExpanded = false;

		if (triggerButton) { 	

			triggerButton.addEventListener("click", function() {
				if (!isExpanded) {
					hiddenListItems.forEach((item) => item.classList.remove("hidden"));
					triggerButton.innerHTML = '<i class="icon-minus"></i> <span>Mai putine locatii</span>';
				} else {
					hiddenListItems.forEach((item) => item.classList.add("hidden"));
					triggerButton.innerHTML = '<i class="icon-plus"></i> <span>Mai multe locatii</span>';
				}

				isExpanded = !isExpanded;
			});
			
		}	

	});

	// Lazy loading
	// https://apoorv.pro/lozad.js/#usage

	const observer = lozad(); // lazy loads elements with default selector as '.lozad'
	observer.observe();

	//
	
});
